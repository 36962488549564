import { useNavigation, Form, useActionData } from '@remix-run/react';
import { Avatar } from '@/components/ui/avatar';
import type { ActionFunction } from '@remix-run/node';
import { json } from '@remix-run/node';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from '@/components/ui/card';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { MailIcon } from 'lucide-react';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '@/components/ui/dialog';

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  const email = formData.get('email');
  const magicLink = formData.get('magicLink');

  if (magicLink) {
    const apiUrl = process.env.VITE_API_URL;
    const response = await fetch(`${apiUrl}/users/magic_link`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });

    if (response.ok) {
      return json({ success: true, message: 'Magic link sent to your email' });
    } else {
      return json({ success: false, message: 'Failed to send magic link' });
    }
  }

  return null;
};

export default function Signin() {
  const navigation = useNavigation();
  const actionData = useActionData<typeof action>();
  const [showDialog, setShowDialog] = useState(false);

  const apiUrl = import.meta.env.VITE_API_URL;
  const googleAuthUrl = `${apiUrl}/users/auth/google_oauth2`;

  const isLoading =
    navigation.state === 'submitting' || navigation.state === 'loading';

  useEffect(() => {
    try {
      posthog.reset();
    } catch (error) {
      console.error('Error identifying user:', error);
    }
  }, []);

  useEffect(() => {
    if (actionData?.success) {
      setShowDialog(true);
    }
  }, [actionData]);

  const onClose = () => setShowDialog(false);

  return (
    <div className="flex items-center justify-center min-h-screen bg-background-tertiary">
      <Avatar
        src="/logo.png"
        alt="Logo"
        className="w-10 h-10 absolute top-6 left-6"
      />
      <Card className="w-[350px]">
        <CardHeader>
          <CardTitle>Sign In</CardTitle>
        </CardHeader>
        <CardContent>
          <Form method="post">
            <Input
              type="email"
              name="email"
              placeholder="Enter your email"
              required
            />
            <input type="hidden" name="magicLink" value="true" />
            <Button type="submit" className="w-full mt-2" disabled={isLoading}>
              {isLoading ? 'Sending...' : 'Email Magic Link'}
            </Button>
          </Form>

          {actionData?.error && (
            <p className="mt-2 text-sm text-center text-red-500">
              {actionData.message}
            </p>
          )}
        </CardContent>
        <CardFooter className="flex flex-col space-y-4">
          <a href={googleAuthUrl} className="w-full">
            <Button
              variant="outline"
              className="w-full flex items-center justify-center space-x-2"
              disabled={isLoading}
            >
              <FaGoogle className="w-5 h-5" />
              <span>Sign in with Google</span>
            </Button>
          </a>
        </CardFooter>
      </Card>

      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>Check Your Email</DialogHeader>
          <DialogBody>
            <div className="">
              <h2 className="text-2xl font-semibold tracking-tight text-center">
                We've emailed you a magic link.
              </h2>

              <p className="text-center mt-4 mb-1">
                Click the link in the email to sign in to your account.
              </p>

              <p className="text-sm text-muted-foreground text-center">
                If you don't see the email, please check your spam folder.
              </p>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button onClick={onClose} className="w-full">
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
